<template>
	<div>
		<v-data-table 
			:headers="headers" 
			:items="ordenesServicio" 
			class="elevation-1" 
			small
			hide-default-footer
			disable-pagination
		>
			<template v-slot:top>
				<v-col cols="12">
					<v-row align="center" justify="center">
						<v-col cols="12" md="3">
							<v-menu>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field 
										class="pt-5" 
										v-model="dateRangeText" 
										label="Intervalo de fechas" 
										persistent-hint 
										readonly
										append-icon="mdi-calendar-multiple" 
										v-bind="attrs" 
										v-on="on" 
										clearable 
										click:clear="dates=''" 
									/>
								</template>
								<v-date-picker 
									range 
									v-model="dates" 
									no-title 
									@change="searchOrdenes" 
								/>
							</v-menu>
						</v-col>
						<v-col cols="12" md="2">
							<v-autocomplete 
								label="Estado" 
								v-model="statusId" 
								:items="states" 
								dense
								item-value="status_id" 
								item-text="description" 
								hide-details="auto"
								@change="searchOrdenes" 
								clearable
							/>
						</v-col>
						<v-col cols="12" md="3">
							<v-autocomplete 
								label="Servicios" 
								v-model="servicioCrmId" 
								:items="servicios" 
								dense
								item-value="servicio_crm_id" 
								item-text="nombre" 
								hide-details="auto"
								@change="searchOrdenes" 
								clearable
							/>
						</v-col>
						<v-col cols="12" md="2">
							<v-combobox 
								type="search"
								clearable
								ref="combobox" 
								class="mt-1" 
								v-model="clienteId"
								:search-input.sync="searchClienteList"
								hide-details 
								:items="clientes" 
								label="Cliente / Paciente" 
								item-text="nombre"
								item-value="party_id" 
								dense 
								@change="searchOrdenes"
								@keydown.enter="obtenerClientes"
							/>
						</v-col>
						<v-col cols="12" md="2">
							<v-autocomplete 
								label="Facturación" 
								v-model="tieneInvoice" 
								:items="estadosInvoices" 
								dense
								item-value="id" 
								item-text="nombre" 
								hide-details="auto"
								@change="searchOrdenes" 
								clearable
							/>
						</v-col>
						<v-col 
							cols="12"
							md="2"
							class="py-0"
						>
							<v-text-field 
								type="search"
								v-model="ordenServicioId" 
								label="Ord. de servicio" 
								@keyup.enter="searchOrdenes"
							/>
						</v-col>
						<v-col 
							cols="12"
							md="2"
							class="py-0"
						>
							<v-text-field 
								type="search"
								v-model="cotizacionId" 
								label="Cotización" 
								append-icon="mdi-magnify"
          						@click:append="searchOrdenes"
								@keyup.enter="searchOrdenes"
							/>
						</v-col>
						<v-col 
							cols="12"
							md="2"
							class="py-0"
						>
							<v-text-field 
								type="search"
								v-model="oportunidadId" 
								label="Oportunidad" 
								append-icon="mdi-magnify"
          						@click:append="searchOrdenes"
								@keyup.enter="searchOrdenes"
							/>
						</v-col>	
						<v-col cols="12" sm="2">
							<v-btn
								color="success"
								small
								@click="descargarOrdenes"
							>
								<v-icon>mdi-file-excel</v-icon> DESCARGAR ORDENES
							</v-btn>
						</v-col>
						<v-col cols="12" sm="2">
							<v-btn
								color="success"
								small
								@click="descargarOrdenesSinCita"
							>
								<v-icon>mdi-file-excel</v-icon> ORDENES SIN CITA
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</template>
			<template v-slot:item.servicio="{ item }">
				{{ item.servicio }} <b>{{ item.valoracion ? '(VALORACIÓN)' : '' }}</b>
			</template>
			<template v-slot:item.orden_servicio_id="{ item }">
				<v-btn
					target="_blank"
					:to="`/genera-orden-servicio/${item.cotizacion_id}/${item.orden_servicio_id}/${item.opportunity_id}`"
					text 
					small 
					v-if="item.valoracion"
				>
					<v-icon small left color="green">mdi-eye</v-icon>
					{{ item.orden_servicio_id }}
				</v-btn>
				<v-btn
					target="_blank"
					:to="`/genera-orden-servicio/${item.cotizacion_id}/${item.orden_servicio_id}`"
					text 
					small 
					v-else
				>
					<v-icon small left color="green">mdi-eye</v-icon>
					{{ item.orden_servicio_id }}
				</v-btn>
			</template>
			<template v-slot:item.opportunity_id="{ item }">
				<v-btn
					target="_blank"
					:to="`/oportunidad_form/${item.opportunity_id}`"
					title="abrir oportunidad"
					text 
					small 
				>
					<v-icon color="blue" left small>mdi-handshake</v-icon>	{{ item.opportunity_id }}
				</v-btn>
			</template>
			<template v-slot:item.cotizacion_id="{ item }">
				<v-btn
					target="_blank"
					:to="`/crm_cotizacion?sop_id=${item.opportunity_id}&quote_id=${item.cotizacion_id}`"
					text 
					title="abrir cotización"
					small 
				>
					<v-icon color="blue" left small>mdi-receipt-text-arrow-right</v-icon>
					<span :class="item.invoice_id == null ? 'red--text' : ''">{{ item.cotizacion_id }}</span>
				</v-btn>
			</template>
			<template v-slot:footer>
				<v-pagination 
					class="mt-10" 
					v-model="currentPage" 
					:length="pageCount" 
					@input="handlePageChange"
					total-visible="10"
				/>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import Vue from "vue"
import moment from "moment"

export default {
	name: "ListaOrdenesServicio",
	data: () => ({
		show: false,
		dates: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
		requiredRule: [(v) => !!v || "El campo es requerido"],
		options: {},
		setfilters: [],
		selected: [],
		users: [],
		servicios:[],
		states:[],
		clientes: [],
		pacientes: [],
		estadosInvoices:[
			{id:null, nombre: 'Todos'},
			{id:false, nombre: 'Sin factura'},
			{id:true, nombre: 'Con factura'}
		],
		tieneInvoice:null,
		statusId:'OS_CREADA',
		servicioCrmId:null,
		oportunidadId:null,
		cotizacionId:null,
		searchClienteList: null,
		searchPacientesList:null,
		ordenServicioId:null,
		clienteId:'',
		pacienteId:'',
		busCodCliente: "",
		totalItems: 1,
		currentPage: 1,
		itemsPerPage: 1,
		pageSize: 20,
		pageCount: 0,		
		required: [(v) => !!v || "El campo es requerido"],
		headers: [
			{ text: "Orden", align: "start", value: "orden_servicio_id" },
			{ text: "Servicio", align: "start", value: "servicio" },
			{ text: "Cod. Cliente", align: "start", value: "party_id" },
			{ text: "Cliente", align: "start", value: "cliente_nombres" },
			{ text: "Cod. paciente", align: "start", value: "party_id_paciente" },
			{ text: "Paciente", align: "start", value: "paciente_nombres" },
			{ text: "Cotización", align: "start", value: "cotizacion_id" },
			{ text: "Oportunidad", align: "start", value: "opportunity_id" },
			{ text: "Estado", align: "start", value: "estado" },
			{ text: "Creado por", align: "start", value: "created_by_user_login" },
			{ text: "Fecha", align: "start", value: "created_stamp" },
		],
		ordenesServicio: [],
		eventos: [],
		tipoServicios: [],
	}),
	computed: {

		...mapState("master", ["loadingBtn", "companies"]),

		...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

		...mapState("master", ["loadingTable", "tenantId", "user"]),

		dateRangeText() {

			return this.dates.join(" ~ ")

		}

	},
	methods: {

		...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

		...mapActions("master", ["requestApi", "alertNotification"]),

		cargarDatos() {

			this.setLoadingTable(true)

			this.setUrl("orden-servicio")

			this.requestApi({
				method: "GET",
				data: {
				},
			}).then((res) => {

				console.log(res);
				this.servicios = res.data.detail.servicios
				this.states = res.data.detail.estados

			}).then(() => {
				this.setLoadingTable(false);
			})
			
		},

		listarOrdenServicio() {
	
			this.setLoadingTable(true)

			this.setUrl('orden-servicio/0')

			this.requestApi({
				method: 'GET',
				data: {
					typeView : 'LISTADO',
					page: this.currentPage,
					pageSize: this.pageSize,
					statusId: this.statusId,
					servicioCrmId: this.servicioCrmId,
					clientId: typeof this.clienteId == 'object' && this.clienteId != null ? this.clienteId.party_id : null,
					quoteId: this.cotizacionId,
					oportunidadId: this.oportunidadId,
					busClienteId: this.busCodCliente,					
					tieneInvoice: this.tieneInvoice,
					ordenServicioId: this.ordenServicioId,
					dates: this.dateRangeText.split('~'),
				}
			}).then(res => {

				console.log('res',res.data)
				this.ordenesServicio = res.data.ordenes
				this.pageCount = res.data.pageCount

				this.setLoadingTable(false)
				
			}).catch(() => {
				
				this.setLoadingTable(false)

			})

		},

		searchOrdenes() {

			if (this.dateRangeText.split("~").length === 2)
				this.listarOrdenServicio()

		},

		handlePageChange(value) {
            this.currentPage = value
            this.listarOrdenServicio()
        },

		obtenerClientes(){
			
			if(this.searchClienteList!=''){

				this.clientes = []

				this.setUrl('orden-servicio/0')
				
				this.requestApi({
					method: "GET",
					data: {
						typeView: 'GET_CLIENTES',
						cliente: this.searchClienteList

					},
				}).then(res => {

					console.log(res)
					this.clientes = res.data.persons

				}).catch(err => {

					console.log(err)
				
				})
				
			}

		},

		obtenerPacientes(){
			
			if(this.searchClienteList!=''){

				this.clientes = []

				this.setUrl('orden-servicio/0')
				
				this.requestApi({
					method: "GET",
					data: {
						typeView: 'GET_PACIENTES',
						cliente: this.searchClienteList

					},
				}).then(res => {

					console.log(res)
					this.clientes = res.data.persons

				}).catch(err => {

					console.log(err)
				
				})
				
			}

		},

		descargarOrdenes(){

			this.overlay = true

			this.setUrl("orden-servicio");
			this.requestApi({
				method: "POST",
				data: {
					typeStore: "descargarOrdenesServicio",
					statusId: this.statusId,
					servicioCrmId: this.servicioCrmId,
					clientId: typeof this.clienteId == 'object' && this.clienteId != null ? this.clienteId.party_id : null,
					quoteId: this.cotizacionId,
					oportunidadId: this.oportunidadId,
					tieneInvoice: this.tieneInvoice,
					dates: this.dateRangeText.split('~')
				},
			}).then(res => {

				console.log(res.data.res)
				let link = document.createElement("a")
				link.href = res.data.res.ordenes[0]
				link.download = "Ordenes de servicio.xlsx"
				link.click()

			}).catch(() => { })
			.then(() => {

				this.overlay = false
			})

		},

		descargarOrdenesSinCita(){

			this.overlay = true

			this.setUrl("orden-servicio");
			this.requestApi({
				method: "POST",
				data: {
					typeStore: "descargarOrdenesServicio",
					statusId: this.statusId,
					servicioCrmId: this.servicioCrmId,
					clientId: typeof this.clienteId == 'object' && this.clienteId != null ? this.clienteId.party_id : null,
					quoteId: this.cotizacionId,
					oportunidadId: this.oportunidadId,
					sinCitaAgendada: true,
					dates: this.dateRangeText.split('~')
				},
			}).then(res => {

				console.log(res.data.res)
				let link = document.createElement("a")
				link.href = res.data.res.ordenes[0]
				link.download = "Ordenes de servicio sin cita.xlsx"
				link.click()

			}).catch(() => { })
			.then(() => {

				this.overlay = false

			})

		}

	},
	mounted() {

		this.cargarDatos()

		this.listarOrdenServicio()

		this.setTitleToolbar("ORDENES DE SERVICIO")

	}
}
</script>
